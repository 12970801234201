import _definePage_default_0 from '/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/charts/index.vue?definePage&vue'
import _definePage_default_3 from '/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/counter/index.vue?definePage&vue'
import _definePage_default_4 from '/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/keepalive/index.vue?definePage&vue'
import _definePage_default_5 from '/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/mock/index.vue?definePage&vue'
import _definePage_default_6 from '/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/profile/index.vue?definePage&vue'
import _definePage_default_7 from '/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukok/index.vue?definePage&vue'
import _definePage_default_8 from '/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/unocss/index.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'home',
    component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/charts',
    /* internal name: '/charts' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'charts',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/charts/index.vue'),
        /* no children */
      },
  _definePage_default_2
  )
    ],
  },
  {
    path: '/counter',
    /* internal name: '/counter' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'counter',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/counter/index.vue'),
        /* no children */
      },
  _definePage_default_3
  )
    ],
  },
  {
    path: '/keepalive',
    /* internal name: '/keepalive' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'KeepAlive',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/keepalive/index.vue'),
        /* no children */
      },
  _definePage_default_4
  )
    ],
  },
  {
    path: '/mock',
    /* internal name: '/mock' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'mock',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/mock/index.vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  {
    path: '/profile',
    /* internal name: '/profile' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'profile',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/profile/index.vue'),
        /* no children */
      },
  _definePage_default_6
  )
    ],
  },
  {
    path: '/shataukok',
    /* internal name: '/shataukok' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'shataukok',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukok/index.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
      {
        path: 'Adv',
        name: '/shataukok/Adv',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukok/Adv.vue'),
        /* no children */
      },
      {
        path: 'Robot',
        name: '/shataukok/Robot',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukok/Robot.vue'),
        /* no children */
      },
      {
        path: 'SelectRouter',
        name: '/shataukok/SelectRouter',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukok/SelectRouter.vue'),
        /* no children */
      },
      {
        path: 'StepPointRouter',
        name: '/shataukok/StepPointRouter',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukok/StepPointRouter.vue'),
        /* no children */
      },
      {
        path: 'SwipePhoto',
        name: '/shataukok/SwipePhoto',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukok/SwipePhoto.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/shataukokh5',
    /* internal name: '/shataukokh5' */
    /* no component */
    children: [
      {
        path: '',
        name: '/shataukokh5/',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukokh5/index.vue'),
        /* no children */
      },
      {
        path: 'Adv',
        name: '/shataukokh5/Adv',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukokh5/Adv.vue'),
        /* no children */
      },
      {
        path: 'Robot',
        name: '/shataukokh5/Robot',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukokh5/Robot.vue'),
        /* no children */
      },
      {
        path: 'SelectRouter',
        name: '/shataukokh5/SelectRouter',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukokh5/SelectRouter.vue'),
        /* no children */
      },
      {
        path: 'StepPointRouter',
        name: '/shataukokh5/StepPointRouter',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukokh5/StepPointRouter.vue'),
        /* no children */
      },
      {
        path: 'SwipePhoto',
        name: '/shataukokh5/SwipePhoto',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukokh5/SwipePhoto.vue'),
        /* no children */
      },
      {
        path: 'test',
        name: '/shataukokh5/test',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/shataukokh5/test.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/unocss',
    /* internal name: '/unocss' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'unocss',
        component: () => import('/opt/devops/shataukok/sit/sourcecode/shataukok_phone/src/pages/unocss/index.vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  }
]
