<!-- eslint-disable no-console -->
<script setup lang="ts">
import { onMounted, ref } from 'vue'

// pinia
import { storeToRefs } from 'pinia'

// component
import SelectRouter from './SelectRouter.vue'
import StepPointRouter from './StepPointRouter.vue'
import Adv from './Adv.vue'
import Robot from './Robot.vue'
import SwipePhoto from './SwipePhoto.vue'

import useShaTauKokStore from '@/stores/modules/shaTauKok'

// i18n 相关
import { locale } from '@/utils/i18n'

definePage({
  name: 'shataukok',
  meta: {
    level: 2,
    // title: '玩转沙头角',
    // i18n: 'home.stkDemo',
  },
})
// const {  locale } = useI18n()
const shaTauKokStore = useShaTauKokStore()
const { i18nState } = storeToRefs(shaTauKokStore)
const {
  activeRouter,
  activePoint,
  fristChangeRouter,
  interactiveVoice,
  robotVoice,
  stkData,
} = storeToRefs(shaTauKokStore)

const languageColumns = ['zh-HK', 'zh-CN', 'en-US']

const languageActive = ref< | 0 | 1 | 2>(0) // 0 = 粤语 1 = 普通话  2 = 英语

onMounted(async () => {
  const index = languageColumns.findIndex(f => f === locale.value)

  languageActive.value = index as 0 | 1 | 2

  i18nState.value = locale.value

  await nextTick()
  onchange(0)
})

async function onchange(e) {
  console.log('onchange')
  // 多语言
  locale.value = languageColumns[e] as string
  // 样式切换
  languageActive.value = e
  // 状态切换
  i18nState.value = languageColumns[e] as string
  await nextTick()
  await playContent()
}
const audioRef = ref(null)

async function playContent() {
  console.log('playContent')

  // 切换路由
  if (!fristChangeRouter.value) {
    console.log('路线欢迎', stkData.value[activeRouter.value], activeRouter.value)
    audioRef!.value.src = stkData.value[activeRouter.value].audios[locale.value][0]
    await audioRef.value?.play().then().catch()
    return
  }
  console.log('点位介绍')
  audioRef!.value.src = stkData.value[activeRouter.value].audios[locale.value][activePoint.value + 1]
  // 播放
  await audioRef.value?.play().then().catch()
  console.log('playContent')
}

function stopContent() {
  // 暂停
  audioRef.value?.pause()
  audioRef!.value.src = null
}

// loading

// 路线切换，点位切换
watch([activeRouter, activePoint, fristChangeRouter], (prev, next) => {
  interactiveVoice.value = interactiveVoice.value++
  const [prevRouter, prevPoint, prevFristChangeRouter] = prev
  const [nextRouter, nextPoint, nextFristChangeRouter] = next
  if (prevPoint !== nextPoint)
    playContent()
  if (prevRouter !== nextRouter)
    playContent()
  if (prevFristChangeRouter !== nextFristChangeRouter)
    playContent()
})

watch(robotVoice, (prev, next) => {
  if (prev !== next)
    stopContent()
})

// local 语言切换
watch(locale, (prev, next) => {
  if (prev !== next) {
    stopContent()
    activeRouter.value = 'ExclusiveSeaRoutes'
    activePoint.value = 0
    fristChangeRouter.value = 0
    playContent()
  }
})

watch(languageActive, (prev, next) => {
  console.log('languageActive', prev, next)
})
</script>

<template>
  <div class="stk-container">
    <!-- 路线选择 & 路线规划 -->
    <div class="router-container">
      <div class="router-content-container">
        <audio ref="audioRef" :muted="false" preload="auto" />
        <!-- <audio ref="audioRef" url="https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3" /> -->
        <SelectRouter />
        <StepPointRouter />
      </div>
    </div>
    <!-- 公告 & 语言切换 & 路线介绍 -->
    <div class="main-container">
      <div class="header-container">
        <!-- 公告 -->
        <Adv />
        <!-- 语言切换 -->
        <div class="language-switching-container">
          <!-- 语言切换 -->
          <div class="language" :class="{ 'language-active': languageActive === 0 }" @click="onchange(0)">
            粤语
          </div>
          <div class="language" :class="{ 'language-active': languageActive === 1 }" @click="onchange(1)">
            普通话
          </div>
          <div class="language" :class="{ 'language-active': languageActive === 2 }" @click="onchange(2)">
            English
          </div>
        </div>
      </div>
      <!-- 路线视图 & AI 交互组件 -->
      <div class="video-photo-container">
        <SwipePhoto />
      </div>
      <!-- AI 交互组件 -->
      <Robot />
    </div>
  </div>

  <div />
</template>

<style lang="less" scoped>
.stk-container {
  position: fixed;
  display: flex;
  justify-content: space-around;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #c6ddfb 0%, #b3d1f3 100%);
  background-image: url('@/assets/shataukok/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  user-select: none;

  .container {
    // margin: 0 auto;
  }

  .router-container {
    flex: 0 596px;
    padding-top: 30px;
    width: 596px;
    height: 980px;
    background: rgba(218, 237, 255, 0.9);
    border-radius: 10px 10px 10px 10px;
    border: 2px solid rgba(255, 255, 255, 0.8);

    .router-content-container {
      width: 532px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .main-container {
    flex: 0 1184px;

    .header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .adv-container {
        flex: 0 870px;
        height: 70px;
        background: linear-gradient(134deg, #e9f2fb 0%, #d2e3f9 100%);
        border-radius: 10px 10px 10px 10px;
        border: 2px solid rgba(255, 255, 255, 0.8);
      }

      .language-switching-container {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;

        flex: 0 302px;
        width: 302px;
        height: 70px;

        background: rgba(255, 255, 255, 0.8);
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #ffffff;

        .language {
          flex: 1 50%;
          width: 100%;
          height: 100%;
          line-height: 70px;
          font-size: 24px;
          text-align: center;
          color: #8794a2;

          &.language-active {
            background: #3678d9;
            border-radius: 10px 10px 10px 10px;
            color: #fff;
          }
        }
      }
    }

    .video-photo-container {
      margin-bottom: 31px;
      width: 1184px;
      height: 669px;
      border-radius: 10px 10px 10px 10px;
      border: 2px solid rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
