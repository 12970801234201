<!-- eslint-disable no-console -->
<script setup lang="ts">
import { ref } from 'vue'

const selectedLanguage = ref('Cantonese')

const active_voice = ref('shanshan')
const promptText = ref('用粤语回答')

async function onLanguageChange() {
  // console.log('Selected language:', selectedLanguage.value)

  // 定义所有可能的前缀
  const prefixes = ['用普通话回答', '用粤语回答', '用英语回答不要译文']

  // 移除 promptText.value 中的特定前缀
  prefixes.forEach((prefix) => {
    if (promptText.value.startsWith(prefix))
      promptText.value = promptText.value.substring(prefix.length).trim()
  })

  // 这个 active_voice的值是固定的，不能随意改成别的

  if (selectedLanguage.value === 'Mandarin') {
    active_voice.value = 'zhixiaoxia'
    promptText.value = `用普通话回答${promptText.value}`
  }
  if (selectedLanguage.value === 'Cantonese') {
    active_voice.value = 'shanshan'
    promptText.value = `用粤语回答${promptText.value}`
  }
  if (selectedLanguage.value === 'English') {
    active_voice.value = 'eva'
    promptText.value = `用英语回答不要译文${promptText.value}`
  }

  // console.log(`1043 promptText：${promptText.value}`)
}
// 创建一个队列来存储音频 URL
const audioQueue = []
let isPlaying = false // 用于跟踪是否有音频正在播放
// 播放音频的函数
function playNextAudio() {
  // 如果队列中还有音频，则播放下一个音频
  if (audioQueue.length > 0) {
    const url = audioQueue.shift() // 从队列中取出下一个音频 URL
    const audio = new Audio(url)
    isPlaying = true // 标记为正在播放

    audio.play().then(() => {
      // console.log('音频播放中...')
    }).catch((error) => {
      console.error('音频播放失败:', error)
      isPlaying = false // 播放失败时重置播放状态
      playNextAudio() // 尝试播放下一个音频
    })

    // 监听音频播放结束事件，当音频播放结束时继续播放下一个音频
    audio.addEventListener('ended', () => {
      isPlaying = false // 标记为未播放
      playNextAudio() // 播放下一个音频
    })
  }
  else {
    // console.log('音频队列已经播放完毕')
    isPlaying = false // 重置播放状态
  }
}
// 假设你有一个函数来获取音频流并将其添加到队列中
function addAudioToQueue(url) {
  audioQueue.push(url)
  // 如果当前没有音频正在播放，则立即开始播放
  if (!isPlaying)
    playNextAudio()
}

async function fetchToken(): Promise<string | null> {
  try {
    const response = await fetch('https://api.guardforceai.cn/config/public/tts/token')
    if (response.ok) {
      const data = await response.json()
      if (data.code === 0) {
        return data.data
      }
      else {
        console.error('Failed to fetch token:', data.desc)
        return null
      }
    }
    else {
      console.error('Network response was not ok:', response.statusText)
      return null
    }
  }
  catch (error) {
    console.error('Fetch token failed:', error)
    return null
  }
}

async function textToSpeech(line: string) {
  console.log(`----tts文本: ${line}`)
  const APPKEY = 'YtQS94GF4ZEWaTEB' // 获取Appkey请前往控制台：https://nls-portal.console.aliyun.com/applist
  const voice = active_voice.value // zhixiaoxia , emily
  const TOKEN = await fetchToken()
  if (!TOKEN) {
    console.error('Failed to retrieve token')
    return
  }
  const response = await fetch(`/stream/v1/tts?appkey=${APPKEY}&token=${TOKEN}&text=${line}&voice=${voice}&format=wav&sample_rate=16000`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-NLS-Token': TOKEN,
    },
  })

  if (response.ok) {
    // 获取响应的 Blob 对象
    const blob = await response.blob()
    // 创建一个 URL 对象来表示 Blob
    const url = URL.createObjectURL(blob)
    addAudioToQueue(url)
  }
  else {
    console.error('网络响应失败', response.statusText)
  }
}

definePage({
  name: 'profile',
  meta: {
    level: 1,
  },
})

const inputText = ref('')
const messages = ref([]) // 消息体
let reader = null // 用于读取流
const history: any[] = [] // 多轮对话记录
let assistantContent = '' // 模型回答的内容
const textQueue: string[] = []
const punctuation = [',', '.', '，', '。', '！', '？', '!', '?']
let partialLine = ''

function preAdd(result) {
  partialLine += result // 拼接当前 result 到 partialLine

  // 从字符串的尾部开始查找标点符号
  let lastIndex = -1
  for (let i = partialLine.length - 1; i >= 0; i--) {
    if (punctuation.includes(partialLine[i])) {
      lastIndex = i
      break // 找到最后一个标点符号后立即退出循环
    }
  }

  if (lastIndex !== -1) {
    // 提取完整的句子并存入 textQueue
    const completeSentence = partialLine.slice(0, lastIndex + 1)
    addLine(completeSentence)

    // 剩余的部分继续存储在 partialLine 中
    partialLine = partialLine.slice(lastIndex + 1)
  }
}

function addLine(line) {
  if (!line)
    return

  textQueue.push(line)
  console.info(textQueue)
  console.info('textQueue.length', textQueue.length)
}

function getLine() {
  if (textQueue.length === 0)
    return null

  return textQueue.shift()
}

async function sendMessage() {
  if (!inputText.value)
    return

  // 用户输入的消息

  const userMessage = inputText.value
  messages.value.unshift(`用户: ${userMessage}`)
  inputText.value = ''

  try {
    history.push(
      {
        role: 'user',
        content: userMessage,
      },
    )

    const body = {
      messages: history,
      stream: true,
      system: promptText.value,
    }

    const response = await fetch('https://aip.baidubce.com/rpc/2.0/ai_custom/v1/wenxinworkshop/chat/ernie-4.0-8k-0329?access_token=24.aabcc8a62d30075be3caa43e47d8fdbd.2592000.1720231971.282335-73419574', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (!response.ok)
      throw new Error(`HTTP error! status: ${response.status}`)

    // 获取 ReadableStream 的 reader
    reader = response.body.getReader()

    messages.value.unshift('模型: ')
    // 递归地读取流中的数据块
    readChunk()
  }
  catch (error) {
    console.error('Error fetching data:', error)
  }
}

async function readChunk() {
  if (textQueue.length > 0)
    textToSpeech(getLine())

  try {
    // 读取下一个数据块
    const { done, value } = await reader.read()
    const decoder = new TextDecoder('utf-8')

    if (!done) {
      const result = decoder.decode(value, { stream: !done })

      const lines = result.split('\n').filter(line => line.startsWith('data:'))
      const jsonObjects = []

      for (const line of lines) {
        const jsonString = line.slice(5).trim() // 去掉 "data:" 前缀并去掉多余的空格
        try {
          const jsonObject = JSON.parse(jsonString) // 解析为 JSON 对象
          jsonObjects.push(jsonObject)
        }
        catch (error) {
          // console.error('Error parsing JSON:', error, 'jsonString:', jsonString);
          // 处理不完整的 JSON 数据，可以选择暂存或忽略 1024
        }
      }

      if (jsonObjects.length > 0) {
        preAdd(jsonObjects[0].result)
        assistantContent += jsonObjects[0].result
        // messages.value[messages.value.length - 1] += jsonObjects[0].result;
        messages.value[0] += jsonObjects[0].result
      }

      readChunk() // 递归调用以继续读取
    }
    else {
      // 如果没有更多数据，关闭reader并可能执行其他清理操作
      history.push({
        role: 'assistant',
        content: assistantContent,
      })
      assistantContent = ''
      reader.releaseLock()
    }
  }
  catch (error) {
    console.error('Error reading chunk:', error)
  }
}
</script>

<template>
  <div class="container">
    <textarea v-model="promptText" class="input-field prompt-text" placeholder="预设指令" />
    <input v-model="inputText" class="input-field" placeholder="输入消息" @keyup.enter="sendMessage">
    <van-button class="send-button" type="primary" @click="sendMessage">
      发送
    </van-button>

    <div class="chat-box" aria-label="聊天内容">
      <div v-if="messages.length === 0" class="placeholder">
        暂无聊天记录
      </div>
      <div v-for="(message, index) in messages" v-else :key="index" class="message">
        {{ message }}
      </div>
    </div>

    <!-- 添加单选按钮组 -->
    <div class="radio-group">
      <label>
        <input v-model="selectedLanguage" type="radio" name="language" value="Cantonese" @change="onLanguageChange"> 粤语
      </label>
      <label>
        <input v-model="selectedLanguage" type="radio" name="language" value="Mandarin" @change="onLanguageChange"> 普通话
      </label>
      <label>
        <input v-model="selectedLanguage" type="radio" name="language" value="English" @change="onLanguageChange"> 英语
      </label>
    </div>
  </div>
</template>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* 添加一些样式以美化单选按钮组 */
.radio-group {
  margin-top: 20px;
}
.radio-group label {
  margin-right: 10px;
}
.input-field {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.prompt-text {
  height: 100px; /* 增加预设指令文本域的高度 */
  resize: vertical; /* 允许用户调整文本域的高度 */
}

.chat-box {
  width: 100%;
  height: 400px; /* 增加聊天记录框的高度 */
  overflow-y: auto; /* 当内容超过容器高度时显示滚动条 */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px; /* 调整聊天记录框与发送按钮之间的间距 */
}

.message {
  margin-bottom: 5px;
}

.placeholder {
  color: #999;
  text-align: center;
  margin-top: 20px;
}

.send-button {
  width: 100%;
  margin-top: 10px; /* 调整发送按钮与输入框之间的间距 */
}
</style>
